/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        jQuery( document ).ready(function(){
          jQuery( '.venue__question' ).click(function(event) {
            event.preventDefault();

            if(jQuery( this ).hasClass('active')){
              jQuery( this ).removeClass( 'active' );
              jQuery( this ).find('.venue__question-arrow').removeClass('arrow-down');
              jQuery( this ).find('.venue__question-arrow').addClass('arrow-right');
              jQuery( this ).next( '.venue__answer' ).slideUp();
            } else {
              jQuery( this ).addClass( 'active' );
              jQuery( this ).find('.venue__question-arrow').removeClass('arrow-right');
              jQuery( this ).find('.venue__question-arrow').addClass('arrow-down');
              jQuery( this ).next( '.venue__answer' ).slideDown();
            }
          });

          var below = document.getElementById("below");
          var sticky = 0;
          if(below) {
            sticky = below.offsetTop-80; 
          } 
          
          function stickIt(){          

            if (window.pageYOffset >= sticky) {
              $('#header').addClass('dark');
              $("#header img").attr("src", templatePath + "/dist/images/logo-hyrlokal-dark.png");
            } else {
              $('#header').removeClass('dark');
              $("#header img").attr("src", templatePath + "/dist/images/logo-hyrlokal-light.png");
            }
          }   

          window.onscroll = function(){ 
            stickIt();
          };  


          $(".b-container").click(function(e){
            e.preventDefault();
            $('#header').addClass('dark');
            $("#header img").attr("src", templatePath + "/dist/images/logo-hyrlokal-dark.png");
          });

          $("#typ").change(function (e) { 
              e.preventDefault();
              if ($(this).val() === "foretag") {
                  $("#foretag").show();
                  $("#privat").hide();
                  $("#privat option:selected").val("null");
              } else if ($(this).val() === "privat") {
                  $("#privat").show();
                  $("#foretag").hide();
                  $("#foretag option:selected").val("null");
              }
          });

          $(".save-favorites").click(function(e) {
            e.preventDefault();
            var lokal_id = $(this).attr('data-id');

            var data = {
              "action": "favorite_action",
              "lokal_id" : lokal_id
            };

            $.ajax({
              type: "POST",
              dataType: 'html', 
              url: Hyrlokalscript.ajax_url,
              data: data, 
              success: function(response) {
                $('.save-favorites').hide();
                $('.list-favorites').show();
              },
              error: function(xhr, resp, text) {
                console.log(xhr, resp, text);
              }
            });
          });

          $(".venue-card__favorite").click(function(e) {
            e.preventDefault();
            var lokal_id = $(this).attr('data-id');

            var data = {
              "action": "favorite_action",
              "lokal_id" : lokal_id
            };

            $.ajax({
              context: this, 
              type: "POST",
              dataType: 'html', 
              url: Hyrlokalscript.ajax_url,
              data: data, 
              success: function(response) {
                $(this).addClass('favorite');
              },
              error: function(xhr, resp, text) {
                console.log(xhr, resp, text);
              }
            });
          });

          $(".delete-modal").click(function(event) {
              var lokal_id = $(this).attr('data-id');
              $('.delete-favorite').attr('data-id', lokal_id);
              $('.modal').show();
          });

          $(".modal__close").click(function(event) {
              $('.modal').hide();
          });

          $(document).click(function(event) {
            //if you click on anything except the modal itself or the "open modal" link, close the modal
            if (!$(event.target).closest(".modal__container, .delete-modal").length) {
              $(".modal").hide();
            }
          });    

          $(".delete-favorite").click(function(e) {
            e.preventDefault();
            var lokal_id = $(this).attr('data-id');
            var data = {
              "action": "delete_action",
              "lokal_id" : lokal_id
            };

            $.ajax({
              type: "POST",
              dataType: 'html', 
              url: Hyrlokalscript.ajax_url,
              data: data, 
              success: function(response) {
                $('.favorite-' + lokal_id).hide('slow');
                $(".modal").hide();
              },
              error: function(xhr, resp, text) {
                console.log(xhr, resp, text);
              }
            });
          });

        });          
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

var slideIndex = 0;

function updateNav() {
  jQuery(".btn-nav").removeClass("active");
  jQuery("[data-index='" + slideIndex + "']").addClass("active");
}  

if (jQuery('.slider').length ) {

  const mySiema = new Siema({
    selector: '.slider',
    duration: 600,
    loop: true,
  });

  jQuery(".btn-nav").on("click", function() {
    var $this = jQuery(this),
      btnIndex = $this.data("index");
    
    slideIndex = btnIndex;
    mySiema.goTo(btnIndex);
    updateNav();
  });

}

if (jQuery('.v-slider').length ) {

  const vSiema = new Siema({
    selector: '.v-slider',
    perPage: {
      1024: 4
    },
    loop: true,
  });

  jQuery(".btn-nav").on("click", function() {
    var $this = jQuery(this),
      btnIndex = $this.data("index");
    
    slideIndex = btnIndex;
    vSiema.goTo(btnIndex);
    updateNav();
  });

}